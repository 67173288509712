import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@michelin/auth';
import { MifaAuthService } from './shared/services/mifa-auth.service';

@Component({
  selector: 'mic-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {

  public claims: Partial<import("@michelin/auth").IdentityClaims>;
  public scopes: string[];

  constructor(private _authService: AuthService,
    private _mifaAuthService: MifaAuthService,
    private _router: Router) {

    this.claims = this._authService.getIdentityClaims();
    this.scopes = this._authService.getGrantedScopes();

    // If login is required
    if (!this._authService.hasValidAccessToken()) {
      this._authService.loadDiscoveryDocument().then(() => {
        this._authService.configure();
        this._authService.tryLoginCodeFlow(null, '');
      });
    }
    
  }

}
