<div class="mic-error" fxFill>
  <div class="mic-error-container">
    <div class="mic-error-panel">
      <div class="oops">Account not found!</div>
      <div class="help"> I already have a Michelin Account (LDAP) :
        <br/>➨ Go to IGA and request access
        <div class="links" icon="file_copy" >
          <a icon="file_copy"  href="https://iga.michelin.com/identityiq/home.jsf">REQUEST ACCESS</a>
        </div>
      </div>

      <div class="help">I do not have a Michelin Account :
        <br/>
        ➨ Contact L2 support team
        <div class="links">
          <a href="mailto:{{supportEmailAccount}}?subject=Create%20Mifa%20Account">CONTACT</a>
        </div>
      </div>

    </div>
    
  </div>
</div>
