import { Component, HostBinding, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ThemingService } from "@michelin/theme";
import { MifaAuthService } from "src/app/shared/services/mifa-auth.service";
import { catchError, of } from "rxjs";
import { MifaUser } from "src/app/shared/models/auth/mifa-user.model";
import { EnvConfigurationService } from "../../../shared/services/envconf.service";

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit{
  public supportEmailAccount: string = EnvConfigurationService.configuration.SUPPORT_EMAIL_ACCOUNT;
  @HostBinding('class') className = this.themingService.theme.className;
  constructor(private readonly themingService: ThemingService,private _mifaAuthService: MifaAuthService, private _route: Router ) {}

  ngOnInit(): void {
    this._mifaAuthService.getUserRolesAndSocieties().pipe(catchError(
      (_err) => {
        return of([]);
      }
    )).subscribe((data) => {
      if(this._mifaAuthService.isMifaUserWithAuthorities(data as MifaUser)){
        this._route.navigate(['/'])
      }
    });
  }
}
