import "hammerjs";
import "moment/min/locales";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { HttpInterceptorService } from "src/app/shared/interceptors/http-interceptor.service";
import { NgModule } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomPaginatorIntlService } from "./shared/services/custom-paginator-intl.service";
import { AuthModule } from "@michelin/auth";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { EnvConfigurationService } from "./shared/services/envconf.service";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    AuthModule.forRoot(EnvConfigurationService.getAuthConfigEnv())
  ],
  declarations: [AppComponent],
  exports: [],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }, {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntlService
    }
  ]
})
export class AppModule {}
