import { Component, Input } from '@angular/core';
import { GLOBAL_CONFIGS } from '../../utils/global-configs';

@Component({
  selector: 'mifa-loader',
  templateUrl: './mifa-loader.component.html',
  styleUrls: ['./mifa-loader.component.scss']
})
export class MifaLoaderComponent {

  public strokeWidth: number = GLOBAL_CONFIGS.STROKE_WIDTH;
  
  @Input() loading: boolean = true;

  constructor() { }

}
