export class GLOBAL_CONSTANTS {
  public static API_URL: string = '/api/v1';
  public static INTEGRATION_DATE: string = 'integrationDate';
  public static LAST_INTEGRATION_DATE: string = 'lastIntegrationDate';
  public static LAST_MODIFICATION_DATE: string = 'lastModificationDate';
  public static DATE_TIME: string = 'dateTime';

  public static RETRY_COUNT = 3;
  public static RETRY_WAIT_TIME = 5000;
  // File constants
  public static MAX_FILE_SIZE_MB: number = 5;
  // Excel max number of lines
  public static WARNING_EXPORT_LINES: number = 10_000;
  public static MAX_EXPORT_LINES: number = 250_000;
  // Modal constants
  public static MAX_ELEMENTS_PER_ARRAY_TO_DISPLAY: number = 500;
  // Alert constants
  public static ALERT_TIMEOUT: number = 2;
}
