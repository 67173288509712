<div fxLayout="column" fxFlexFill>
  <ng-container *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>

  <!-- navbar -->
  <mifa-navbar></mifa-navbar>

  <!-- loader -->
  <ng-container *ngIf="!currentMifaUser || (currentMifaUser && currentMifaUser.userId === 0)">
    <div class="loader-container">
      <mifa-loader [loading]="true"></mifa-loader>
    </div>
  </ng-container>

  <!-- body -->
  <ng-container *ngIf="currentMifaUser && currentMifaUser.userId !== 0">
    <mic-sidebar-container fxFlex leftToggler="white-sidebar">
      <!-- left menu -->
      <mic-sidebar position="start" mode="large">

        <!-- integration pages -->
        <ng-container *ngIf="(currentMifaUser | isAdmin) || (currentMifaUser | isKeyUser) || (currentMifaUser | isUser)">
          <mic-sidebar-item icon="file_upload" label="Upload">
            <mic-sidebar-item link="/upload/integrate-a-file" label="Integrate A File"></mic-sidebar-item>
          </mic-sidebar-item>
        </ng-container>

        <!-- relfac pages -->
        <ng-container *ngIf="(currentMifaUser | isAdmin) || (currentMifaUser | isBackOffice) || (currentMifaUser | isKeyUser) || (currentMifaUser | isUser) || (currentMifaUser | isTreasury)">
          <mic-sidebar-item icon="file_copy" label="Relfac">
            <ng-container *ngIf="!(currentMifaUser | isTreasury)">
              <mic-sidebar-item link="/relfac/monitoring" label="Monitoring"></mic-sidebar-item>
            </ng-container>

            <ng-container *ngIf="!(currentMifaUser | isTreasury)">
              <mic-sidebar-item link="/relfac/integration" label="Integration"></mic-sidebar-item>
            </ng-container>

            <ng-container *ngIf="!(currentMifaUser | isTreasury)">
              <mic-sidebar-item link="/relfac/level-0-files" label="Level 0 Files"></mic-sidebar-item>
            </ng-container>

            <ng-container *ngIf="!(currentMifaUser | isTreasury)">
              <mic-sidebar-item link="/relfac/detailed-invoices" label="Detailed Invoices"></mic-sidebar-item>
            </ng-container>
            
            <ng-container *ngIf="!(currentMifaUser | isUser) && !(currentMifaUser | isKeyUser)">
              <mic-sidebar-item link="/relfac/level-1-repurchase" label="Level 1 Repurchase" ></mic-sidebar-item>
            </ng-container>
           
           
            
           
          </mic-sidebar-item>
        </ng-container>


        <!-- hedging page -->
        <ng-container *ngIf="(currentMifaUser | isAdmin) || (currentMifaUser | isBackOffice) || (currentMifaUser | isTreasury)">
          <mic-sidebar-item icon="currency_exchange" label="Hedging">
            <mic-sidebar-item link="/hedging/final-fx-hedge" label="Final FX Hedge"></mic-sidebar-item>
          </mic-sidebar-item>
        </ng-container>

        <!-- payments page -->
        <ng-container *ngIf="(currentMifaUser | isAdmin) || (currentMifaUser | isBackOffice) || (currentMifaUser | isTreasury)">
          <mic-sidebar-item icon="payment" label="Payment">
            <mic-sidebar-item link="/payment/payments" label="Payments"></mic-sidebar-item>
          </mic-sidebar-item>
        </ng-container>

        <!-- referential pages -->
        <ng-container *ngIf="(currentMifaUser | isAdmin) || (currentMifaUser | isBackOffice) || (currentMifaUser | isKeyUser) || (currentMifaUser | isUser) || (currentMifaUser | isTreasury)">
          <mic-sidebar-item icon="settings" label="Referential">

            <mic-sidebar-item link="/referential/top" label="Top"></mic-sidebar-item>
            <mic-sidebar-item link="/referential/currencies" label="Currencies"></mic-sidebar-item>
            <mic-sidebar-item link="/referential/kambiste-calendar" label="Kambiste Calendar"></mic-sidebar-item>
            <mic-sidebar-item link="/referential/relfac-codes" label="Product Codes"></mic-sidebar-item>

            <ng-container *ngIf="!(currentMifaUser | isUser) && !(currentMifaUser | isKeyUser)  && !(currentMifaUser | isTreasury)">
              <mic-sidebar-item link="/referential/michelin-societies" label="Michelin Societies"></mic-sidebar-item>
              <mic-sidebar-item link="/referential/letters-configuration" label="Configuring Letters"></mic-sidebar-item>
              <mic-sidebar-item link="/referential/general-ledger-configuration" label="General Ledger Configuration"></mic-sidebar-item>
              <mic-sidebar-item link="/referential/quantum-configuration" label="Quantum Configuration"></mic-sidebar-item>

            <mic-sidebar-item link="/referential/async-task" label="Async Tasks"></mic-sidebar-item>

            </ng-container>

           
          </mic-sidebar-item>
        </ng-container>

        <!-- reporting pages -->
        <ng-container *ngIf="(currentMifaUser | isAdmin) || (currentMifaUser | isBackOffice) || (currentMifaUser | isTreasury)">
          <mic-sidebar-item icon="pivot_table_chart" label="Reports">
            <mic-sidebar-item link="/reports/balance-viewer" label="Balance Viewer"></mic-sidebar-item>
            <mic-sidebar-item link="/reports/invoicing-amounts-viewer" label="Invoicing Amounts Viewer"></mic-sidebar-item>
            <mic-sidebar-item link="/reports/fees-statement-viewer" label="Service Fees Statement Viewer"></mic-sidebar-item>

            <ng-container *ngIf="!(currentMifaUser | isTreasury)">
              <mic-sidebar-item link="/reports/manual-publication" label="Manual Publication"></mic-sidebar-item>
              <mic-sidebar-item link="/reports/balance-publication" label="Balance Publication"></mic-sidebar-item>
              <mic-sidebar-item link="/reports/quantum-payments-viewer" label="Quantum Payments Viewer"></mic-sidebar-item>
            </ng-container>
           

          </mic-sidebar-item>
        </ng-container>

        <!-- administration pages -->
        <ng-container *ngIf="(currentMifaUser | isAdmin) || (currentMifaUser | isBackOffice) || (currentMifaUser | isKeyUser) || (currentMifaUser | isUser) || (currentMifaUser | isTreasury)">
          <mic-sidebar-item icon="admin_panel_settings" label="Administration">
            <mic-sidebar-item link="/administration/users" label="Users"></mic-sidebar-item>
            <mic-sidebar-item link="/administration/logs" label="Logs"></mic-sidebar-item>
          </mic-sidebar-item>
        </ng-container>

      </mic-sidebar>

      <div fxLayout="column" fxFlexFill fxLayoutAlign="space-between" content>
        <!-- body -->
        <div class="sidebar-container-content">
          <router-outlet></router-outlet>
        </div>

        <!-- footer -->
        <mic-navbar mode="closely">
          <div fxLayout="row" fxFill>
            <mic-alert-center [hideDelay]="8"></mic-alert-center>
            <div fxFlexOffset="1" fxFlex="24" fxLayoutAlign="start center" class="version">{{ version }}</div>
            <div fxFlex="50" fxLayoutAlign="center center">Copyright Michelin © {{ year }}</div>
            <div fxFlex="24" fxLayoutAlign="end center">
              <div class="toggle" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                <mat-icon fxLayoutAlign="end center">brightness_5</mat-icon>
                <mat-slide-toggle fxLayoutAlign="center center" [formControl]="darkMode"></mat-slide-toggle>
                <mat-icon fxLayoutAlign="start center">bedtime</mat-icon>
              </div>
            </div>
          </div>
        </mic-navbar>
      </div>
    </mic-sidebar-container>
  </ng-container>
</div>
