import { GLOBAL_CONSTANTS } from "./global-constants";

export class MESSAGES {
	// actions
	public static YES: string = 'Yes';
	public static NO: string = 'No';
	public static OK: string = 'Ok';
	public static CREATE: string = 'Create';
	public static EDIT: string = 'Edit';
	public static DETAILS: string = 'Details';
	public static SAVE: string = 'Save';
	public static CANCEL: string = 'Cancel';

	// dialogs messages
	public static UNDER_CONSTRUCTION: string = 'Under Construction';
	public static FEATURE_UNDER_CONSTRUCTION: string = 'This feature is under construction...';
	public static ARE_YOU_SURE_TITLE: string = 'Are you sure?';
	public static ARE_YOU_SURE_MESSAGE: string = 'Are you sure you want to confirm your changes?';
	public static WARNING_EXPORT: string = 'Warning';
	public static ARE_YOU_SURE_EXPORT: string = 'There is a large amount of data to export. Do you wish to proceed?';
	public static ARE_YOU_SURE_UPLOAD: string = 'Are you sure you want to upload this file?';
	public static ARE_YOU_SURE_REPLACE: string = 'Are you sure you want to replace this file?'
	public static ARE_YOU_SURE_DELETE_MESSAGE: string = 'Are you sure you want to delete this record?';
	public static ARE_YOU_SURE_NO_INTERCOMPANY_SALES: string = 'Are you sure you want to declare that there are no intercompany sales available for this closure month?';
	public static ARE_YOU_SURE_DELETE_ALL_MESSAGE: string = 'Are you sure you want to delete these records?';
	public static AT_LEAST_ONE_FIELD_MUST_BE_CHANGED: string = 'At least one field must be changed.';
	public static ARE_YOU_SURE_INTEGRATE_ALL_RELFAC_FILES: string = 'Are you sure you want to integrate all Relfac files of the current closure month to Level 1?';
	public static ARE_YOU_SURE_INTEGRATE_SELECTED_RELFAC_FILES: string = 'Are you sure you want to integrate the selected Relfac files to Level 1?';
	public static ARE_YOU_SURE_INTEGRATE_RELFAC_FILE: string = 'Are you sure you want to integrate this Relfac file to Level 1?';
	public static ARE_YOU_SURE_ADVANCED_ACTION: string = 'Are you sure you want to run this process?';
	public static ARE_YOU_SURE_ADVANCED_ACTION_WITHOUT_FILTERS: string = 'Are you sure you want to apply this process to all records?';
	public static ARE_YOU_SURE_ADVANCED_ACTION_WITH_FILTERS: string = 'Are you sure you want to apply this process to all records that match the applied filters?';
	public static THIS_SOCIETY_IS_NOT_PART_OF_YOUR_LIST='This action cannot be performed because the selling company is not part of your company list. ';

  // error messages
	public static DEFAULT_ERROR_MESSAGE = 'An unexpected error was encountered.';
  public static UNAUTHORIZED_RESOURCE_MESSAGE = 'An unauthorized operation was encountered.'
	public static TOO_MANY_LINES_EXPORT = 'Too many lines > ' + `${GLOBAL_CONSTANTS.MAX_EXPORT_LINES}` + ' to export.';
}
