import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Alert, AlertService, AlertType} from '@michelin/theme';
import {concatMap, Observable, of, retryWhen, tap, throwError} from 'rxjs';
import {delay} from 'rxjs/operators';
import {GLOBAL_CONSTANTS} from 'src/app/shared/utils/global-constants';
import {UtilService} from '../services/util.service';
import {MESSAGES} from '../utils/messages';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private readonly _alertService: AlertService, private readonly _utilService: UtilService, private _router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      // Every http request will be retried thrice before failing
      retryWhen(error =>
        error.pipe(
          concatMap((_error, count) => {
            switch (_error.status) {
              case 403:
              case 401:
                if (!_error.message.includes("login-indus-fed.michelin.com")) {
                  this._router.navigate(['error']);
                }
                break;
              case 500:
                if(!_error.message.includes("login-indus-fed.michelin.com"))
                {
                  if (_error.message.includes("is not present.") && _error.message.includes("User")) {
                    this._router.navigate(['error']);
                  }
                } 
                break;
              case 503:
              case 504:
              case 408:
                if (count < GLOBAL_CONSTANTS.RETRY_COUNT) {
                  return of(_error);
                }
                break;
              default:
                break;
            }
            return throwError(_error);
          }),
          delay(GLOBAL_CONSTANTS.RETRY_WAIT_TIME)
        )
      ), tap({
        error: (error) => this.alert(error)
      })
    );

  }

  alert(errorObj: any) {
    if (errorObj instanceof HttpErrorResponse) {

      if (errorObj.error instanceof Blob) {

        errorObj.error.text().then((result) => {
          const errortitle: string = JSON.parse(result).error;
          const errorMessage: string = JSON.parse(result).message;

          this._alertService.showAlert({
            type: errorObj.status === 404 ? AlertType.strong : AlertType.critical,
            title: errortitle,
            content: errorMessage,
            timestamp: 5
          } as Alert);

          this._utilService.sendLoadingStatus(false);
        });

      } else {

        const errortitle: string = errorObj.error?.error;
        let errorMessage: string;

        if (errorObj.status === 403) {
          errorMessage = MESSAGES.UNAUTHORIZED_RESOURCE_MESSAGE;
        } else {
          errorMessage = errorObj.error?.message
            ? errorObj.error.message
            : MESSAGES.DEFAULT_ERROR_MESSAGE;
        }

        this._alertService.showAlert({
          type: errorObj.status === 404 ? AlertType.strong : AlertType.critical,
          title: errortitle,
          content: errorMessage,
          timestamp: 5
        } as Alert);

        this._utilService.sendLoadingStatus(false);

      }

    }
  }
}
