import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { GLOBAL_CONFIGS } from '../utils/global-configs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private readonly isLoadingSubject: Subject<boolean> = new Subject<boolean>();

  constructor() { /* TODO document why this constructor is empty */  }

  sendLoadingStatus(isLoading: boolean) {
    this.isLoadingSubject.next(isLoading);
  }

  isLoading() {
    return this.isLoadingSubject.asObservable();
  }

  formatDateTime(value: any, format: string): string {

    if (format === GLOBAL_CONFIGS.YYYY_MM_DD) {
      return moment(value).format(format);
    }

    if (value instanceof Object) {
      return moment(value).format().substring(0, moment(value).format().length - 1);
    }

    return moment(value).format().substring(0, moment(value).format().length - 6);

  }

  isFormEmpty(formGroup: FormGroup): boolean {
    let checkedField = 0;
    for (const field in formGroup.controls) {// 'field' is a string

      if (formGroup.get(field).value) {
        return false;
      } else {
        checkedField++;
      }
    }

    return true && checkedField !== 0;
  }

  dbValuesFormatter(filterValue: string): string {
    switch (filterValue) {
      case 'Y': return 'Yes';
      case 'N': return 'No';
      case 'F': return 'Supplier Payment';
      case 'C': return 'Customer Payment';
      case 'bank': return 'Bank';
      case 'currentAccount': return 'Current Account';
      case 'MembersTop': return 'Yes';
      case 'NonMembersTop': return 'No';
      case 'NEAREST': return 'Nearest';
      case 'PRECEDING_NEAREST': return 'Previous Nearest';
      case 'FOLLOWING_NEAREST': return 'Following Nearest';
      case 'ADMIN': return 'Admin';
      case 'BACK_OFFICE': return 'Back Office';
      case 'TREASURY': return 'Treasury';
      case 'KEY_USER': return 'Key User';
      case 'USER': return 'User';
      default: return filterValue;
    }
  }

}
