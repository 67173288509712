import { Pipe, PipeTransform } from '@angular/core';
import { MifaUser } from '../../models/auth/mifa-user.model';

@Pipe({
  name: 'isKeyUser'
})
export class IsKeyUserPipe implements PipeTransform {

  transform(value: MifaUser): boolean {
    if (value && value.userAuthorities) {
      return value?.userAuthorities?.includes('KEY_USER');
    }
    return false;
  }

}
