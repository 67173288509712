import { Pipe, PipeTransform } from '@angular/core';
import { MifaUser } from '../../models/auth/mifa-user.model';

@Pipe({
  name: 'isAdmin'
})
export class IsAdminPipe implements PipeTransform {

  transform(value: MifaUser): boolean {
    if (value && value.userAuthorities) {
      return value?.userAuthorities?.includes('ADMIN');
    }
    return false;
  }

}
