import * as moment from 'moment';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemingService } from '@michelin/theme';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/shared/services/util.service';
import { MifaAuthService } from 'src/app/shared/services/mifa-auth.service';
import { MifaUser } from 'src/app/shared/models/auth/mifa-user.model';
import { Router } from '@angular/router';

const darkClassName = 'dark-mode';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public currentMifaUser: MifaUser = null;
  public isLoading: boolean = false;
  public year = moment.utc().format('YYYY');
  public version = environment.version;
  public darkMode: FormControl;

  @HostBinding('class') private className: string;

  constructor(private _themingService: ThemingService,
    private _mifaAuthService: MifaAuthService,
    private _utilService: UtilService,
    private _router: Router) {
    
    // Subscribe to the Authentication service to get the current user
    this._mifaAuthService.getCurrentMifaUser().subscribe(
      (mifaUser) => {
        // console.log('LayoutComponent init - MifaUser: ', mifaUser);
        this.currentMifaUser = mifaUser;
      }
    );

  }

  ngOnInit(): void {

    // Setup dark mode
    this.className = this._themingService.theme.className;
    this.darkMode = new FormControl(this.className === darkClassName);

    // Subscribe to theme changes
    this.darkMode.valueChanges.subscribe((darkMode: boolean) => {
      this.className = this._themingService.display(darkMode ? darkClassName : '', darkMode);
    });

    // Trigger loading bar
    this._utilService.isLoading().subscribe(
      (loadingStatus) => {
        this.isLoading = loadingStatus;
      }
    );
    
  }

}
