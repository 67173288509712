import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@michelin/auth';
import { LayoutComponent } from './core/components/layout/layout.component';
import { ErrorComponent } from './core/pages/error/error.component';

export const routes: Routes = [
  { 
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      // loadChildren() allows for lazy loading of modules -> better performance when app has complex routing
      {
        path: '',
        loadChildren: () => import('./core/core.module').then((m) => m.CoreModule)
      }, {
        path: 'relfac',
        loadChildren: () => import('./modules/relfac/relfac.module').then((m) => m.RelfacModule)
      }, {
        path: 'upload',
        loadChildren: () => import('./modules/upload/upload.module').then((m) => m.UploadModule)
      }, {
        path: 'hedging',
        loadChildren: () => import('./modules/hedging/hedging.module').then((m) => m.HedgingModule)
      }, {
        path: 'payment',
        loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule)
      }, {
        path: 'referential',
        loadChildren: () => import('./modules/referential/referential.module').then((m) => m.ReferentialModule)
      }, {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule)
      }, {
        path: 'administration',
        loadChildren: () => import('./modules/administration/administration.module').then((m) => m.AdministrationModule)
      }
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
