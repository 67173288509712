import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthEvent, AuthService} from '@michelin/auth';
import {BehaviorSubject, catchError, filter, Observable, of} from 'rxjs';
import {MifaUser} from '../models/auth/mifa-user.model';
import {GLOBAL_CONSTANTS} from '../utils/global-constants';

@Injectable({
  providedIn: 'root'
})
export class MifaAuthService {

  private mifaUSerSubject: BehaviorSubject<MifaUser> = new BehaviorSubject<MifaUser>({
    userId: 0,
    michelinId: '',
    userSocieties: [],
    userAuthorities: []
  });
  private currentMifaUser: Observable<MifaUser>;

  constructor(private _http: HttpClient,
              private _authService: AuthService,
              private _router: Router) {

    // Update current user
    this.currentMifaUser = this.mifaUSerSubject.asObservable();

    // Check if access token exists (user already logged in)
    if (this._authService.hasValidAccessToken()) {
      this.getUserRolesAndSocieties().pipe(catchError(
        (_err) => {
          return of([]);
        }
      )).subscribe((data) => {
        this.setCurrentMifaUser(data as MifaUser);
      });
    }

    // Observer of local session
    this._authService.events.pipe(
      filter((e: AuthEvent) => {
        return e.type === 'token_received';
      })
    ).subscribe((_) => {

      this.getUserRolesAndSocieties().pipe(catchError(
        (_err) => {
          return of([]);
        }
      )).subscribe((data) => {
        this.setCurrentMifaUser(data as MifaUser);
      });

    });

  }

  // Login method
  getUserRolesAndSocieties(): Observable<MifaUser> {
    return this._http.get<MifaUser>(GLOBAL_CONSTANTS.API_URL + '/account');
  }

  setCurrentMifaUser(mifaUser: MifaUser): void {
    this.isMifaUserWithAuthorities(mifaUser) ? this.mifaUSerSubject?.next(mifaUser) : this._router.navigate(['error']);
  }

  isMifaUserWithAuthorities(mifaUser: MifaUser): boolean {
    return mifaUser.userAuthorities?.length >= 1;
  }

  getCurrentMifaUser(): Observable<MifaUser> {
    return this.currentMifaUser;
  }

}
