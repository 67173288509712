import { Pipe, PipeTransform } from '@angular/core';
import { MifaUser } from '../../models/auth/mifa-user.model';

@Pipe({
  name: 'isBackOffice'
})
export class IsBackOfficePipe implements PipeTransform {

  transform(value: MifaUser): boolean {
    if (value && value.userAuthorities) {
      return value.userAuthorities.includes('BACK_OFFICE');
    }
    return false;
  }

}
