import { Injectable } from '@angular/core';
import { AuthConfig } from '@michelin/auth';

export interface EnvConfiguration {
  FORGEROCK_ISSUER: string,
  FORGEROCK_CLIENTID: string,
  oauth2_scopes: string,
  oauth2_redirect_uri: string,
  BACKEND_URL: string,
  POST_LOGOUT_REDIRECT_URI: string,
  SUPPORT_EMAIL_ACCOUNT: string
}

interface CustomWindow extends Window {
  // add you custom properties and methods
  dynamicConf: Record<string, unknown>;
}

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  public static loadDynamicConf(): void {
    EnvConfigurationService.configuration = {
      ...EnvConfigurationService.configuration,
      ...(window as CustomWindow & typeof globalThis).dynamicConf
    };
  }

  // This is default configuration, these values are public and can be retreive in production.
  // They are overriden with config.json provided with a k8s configmap. See config.json in .k8s overlays folder
  public static configuration: EnvConfiguration = {
    FORGEROCK_ISSUER: 'https://login-indus-fed.michelin.com:443/am/oauth2',
    FORGEROCK_CLIENTID: 'DEV-MIF',
    oauth2_scopes: 'openid profile email',
    oauth2_redirect_uri: 'https://mif-dev.dk8seur2.aze.michelin.com',
    BACKEND_URL: 'https://mif-dev.dk8seur2.aze.michelin.com/api',
    POST_LOGOUT_REDIRECT_URI: 'https://mif-dev.dk8seur2.aze.michelin.com/login?loggedout=true',
    SUPPORT_EMAIL_ACCOUNT: 'compte-fonction.compta-treso-supp@michelin.com',
  };

  /**
   * Provider configuration for authent lib reusable.
   *
   * @returns Configuration for authent lib
   */
  public static getAuthConfigEnv(): AuthConfig {
    return {
      authOnAppInitializer: true,
      issuer: EnvConfigurationService.configuration.FORGEROCK_ISSUER,
      redirectUri: EnvConfigurationService.configuration.oauth2_redirect_uri,
      clientId: EnvConfigurationService.configuration.FORGEROCK_CLIENTID,
      scope: EnvConfigurationService.configuration.oauth2_scopes,
      postLogoutRedirectUri: EnvConfigurationService.configuration.POST_LOGOUT_REDIRECT_URI,
      bearerAllowedUrls: [EnvConfigurationService.configuration.BACKEND_URL],
      sessionChecksEnabled: false
    };
  }
}

EnvConfigurationService.loadDynamicConf();
