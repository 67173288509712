import {Component, HostBinding} from '@angular/core';
import {AuthService} from '@michelin/auth';
import {ThemingService} from '@michelin/theme';
import {MifaAuthService} from '../../../shared/services/mifa-auth.service';
import {MifaUser} from '../../../shared/models/auth/mifa-user.model';

@Component({
  selector: 'mifa-navbar',
  templateUrl: './mifa-navbar.component.html',
  styleUrls: ['./mifa-navbar.component.scss']
})
export class MifaNavbarComponent {

  @HostBinding('class') private readonly className = this._themingService.theme.className;
  public currentMifaUser: MifaUser;
  title = 'Mifa';

  constructor(private readonly authService: AuthService, private readonly _themingService: ThemingService, private readonly _mifaAuthService: MifaAuthService) {
    // Subscribe to the Authentication service to get the current user

    this._mifaAuthService.getCurrentMifaUser().subscribe(
      (mifaUser) => {
        this.currentMifaUser = mifaUser;
      }
    );
  }

  logout() {
    this.authService.logOut();
  }

}
