<mic-navbar>
	<mic-sidebar-toggler [alwaysDisplay]="true" id="white-sidebar"></mic-sidebar-toggler>

	<a routerLink="/">
		<mic-logo></mic-logo>
	</a>

	<mic-navbar-spacer></mic-navbar-spacer>

	<a routerLink="/" class="title" fxHide.lt-md>
		{{ title }}
	</a>

	<mic-navbar-spacer></mic-navbar-spacer>

  <span fxHide.lt-md>{{ currentMifaUser.michelinId }}</span>
</mic-navbar>
