import {MifaOption} from '../models/form/mifa-option.model';
import {FileMapping} from '../models/mifa-publish/file-mapping.model';
import {RelfacBlockMapping} from '../models/mifa-publish/relfac-block-mapping.model';

export class GLOBAL_CONFIGS {

  public static MICHELIN_SOCIETIES_TOP_ENDPOINT: string = '/referential/michelin-societies/tops';
  public static MICHELIN_SOCIETIES: string = '/referential/michelin-societies';

  public static PREFIX_MONEY_AMOUNT: string = "amount";
  public static PREFIX_MONEY_TO_HEDGE: string = "tohedge";


  /** Spinner */
  public static STROKE_WIDTH: number = 8;

  /** Mifa Table */
  public static DEFAULT_PAGE_SIZE: number = 50;
  public static DEFAULT_DIALOG_DATA_TABLE_SIZE: number = 10;
  public static DEFAULT_PAGE_SIZES: number[] = [5, 10, 25, 50, 100];
  public static SORT_ARROW_ALIGNMENT: 'left' | 'right' = 'left';
  public static EXCEL_FILE_TYPE: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
  public static YES: string = 'Y';
  public static NO: string = 'N';

  /** Dialog */
  public static DIALOG_WIDTH: string = '65%';

  /** Advanced Search */
  public static LOWER_BOUND: string = '_lowerBound';
  public static GREATER_BOUND: string = '_greaterBound';

  public static PAYMENT_TYPES: MifaOption[] = [
    {
      code: 'F',
      description: 'Supplier Payment'
    }, {
      code: 'C',
      description: 'Customer Payment'
    }
  ];

  public static HEDGING_TYPES: MifaOption[] = [
    {
      code: 'V',
      description: 'Invoice Currency Sold'
    }, {
      code: 'A',
      description: 'Invoice Currency Bought'
    }
  ];

  public static SOCIETIES_PAYMENT_TYPES: MifaOption[] = [
    {
      code: 'bank',
      description: 'Bank'
    }, {
      code: 'currentAccount',
      description: 'Current Account'
    }
  ];

  public static BALANCE_TYPES: MifaOption[] = [
    {
      code: 'supplierBalance',
      description: 'Supplier Balance'
    }, {
      code: 'customerBalance',
      description: 'Customer Balance'
    }
  ];

  public static INVOICE_TYPES: MifaOption[] = [
    {
      code: 'supplierInvoice',
      description: 'Supplier Invoice'
    }, {
      code: 'customerInvoice',
      description: 'Customer Invoice'
    }
  ];

  public static TREASURY_ZONES: MifaOption[] = [
    {
      code: 'AMN',
      description: 'AMN'
    }, {
      code: 'CHINA',
      description: 'CHINA'
    }, {
      code: 'E2A',
      description: 'E2A'
    }, {
      code: 'EUR',
      description: 'EUR'
    }, {
      code: 'TIGAR',
      description: 'TIGAR'
    }
  ];

  public static RR_ZONES: MifaOption[] = [
    {
      code: 'R_AIM',
      description: 'R_AIM'
    }, {
      code: 'R_AMC',
      description: 'R_AMC'
    }, {
      code: 'R_AMN',
      description: 'R_AMN'
    }, {
      code: 'R_AMS',
      description: 'R_AMS'
    }, {
      code: 'R_CHN',
      description: 'R_CHN'
    }, {
      code: 'R_E2A',
      description: 'R_E2A'
    }, {
      code: 'R_EUC',
      description: 'R_EUC'
    }, {
      code: 'R_EUN',
      description: 'R_EUN'
    }, {
      code: 'R_EUO',
      description: 'R_EUO'
    }, {
      code: 'R_EUS',
      description: 'R_EUS'
    }, {
      code: 'R_NRG',
      description: 'R_NRG'
    }
  ];

  public static TRUE_FALSE: MifaOption[] = [
    {
      code: 'Y',
      description: 'True'
    }, {
      code: 'N',
      description: 'False'
    }
  ];

  public static YES_NO: MifaOption[] = [
    {
      code: 'Y',
      description: 'Yes'
    }, {
      code: 'N',
      description: 'No'
    }
  ];

  //Tempory
  public static ONLY_NO: MifaOption[] = [
    {
      code: 'N',
      description: 'No'
    }
  ];

  public static ASYNC_TASK_NAME: MifaOption[] = [
    {
      code: 'customerBalance',
      description: 'Customer Balance'
    }, {
      code: 'supplierBalance',
      description: 'Supplier Balance'
    }, {
      code: 'accountLevel1',
      description: 'Account Level 1'
    },{
      code: 'integrateLevel1',
      description: 'Integrate Level 1'
    }, {
      code: 'accountPayment',
      description: 'Account Payment'
    }

  ];

  public static HEDGING_STATUSES: MifaOption[] = [
    {
      code: 'MISMATCH',
      description: 'Mismatch'
    }, {
      code: 'NO PAYMENT FOUND',
      description: 'No Payment Found'
    }, {
      code: 'OK TO MATCH',
      description: 'Ok To Match'
    }
  ];

  public static PAYMENT_STATUSES: MifaOption[] = [
     {
      code: 'NO FX FOUND',
      description: 'No Fx Found'
    }, {
      code: 'FX FOUND',
      description: 'Fx Found'
    },{
      code: 'NO FX',
      description:'No Fx'
    }
  ];

  public static LEVEL_ZERO_STATUSES: MifaOption[] = [
    {
      code: 'INTEGRATION OK',
      description: 'Integration Ok'
    }, {
      code: 'INTEGRATION IN PROGRESS',
      description: 'Integration In Progress'
    }, {
      code: 'INTEGRATION FAILED',
      description: 'Integration Failed'
    }
  ];

  public static LEVEL_ONE_STATUSES: MifaOption[] = [
    {
      code: 'NOT INTEGRATED',
      description: 'Not Integrated'
    }, {
      code: 'INTEGRATION NOT APPLICABLE',
      description: 'Integration Not Applicable'
    }, {
      code: 'INTEGRATION FAILED',
      description: 'Integration Failed'
    }, {
      code: 'INTEGRATION OK',
      description: 'Integration Ok'
    }
  ];

  public static MONITORING_STATUSES: MifaOption[] = [
    {
      code: 'FILE NOT RECEIVED',
      description: 'File Not Received'
    }, {
      code: 'LATE',
      description: 'Late'
    }, {
      code: 'PENDING FIX',
      description: 'Pending Fix'
    }, {
      code: 'NOT FIXED',
      description: 'Not Fixed'
    }, {
      code: 'ON TIME',
      description: 'On Time'
    }
  ];

  public static MEMBERS_TOP: MifaOption[] = [
    {
      code: 'MembersTop',
      description: 'Yes'
    }, {
      code: 'NonMembersTop',
      description: 'No'
    }
  ];

  public static CUSTOMER_SUPPLIER_METHOD: MifaOption[] = [
    {
      code: 'NEAREST',
      description: 'Nearest'
    }, {
      code: 'PRECEDING_NEAREST',
      description: 'Previous Nearest'
    }, {
      code: 'FOLLOWING_NEAREST',
      description: 'Following Nearest'
    }
  ];

  public static PROFILES: MifaOption[] = [
    {
      code: 'ADMIN',
      description: 'Admin'
    }, {
      code: 'BACK_OFFICE',
      description: 'Back Office'
    }, {
      code: 'TREASURY',
      description: 'Treasury'
    }, {
      code: 'KEY_USER',
      description: 'Key User'
    }, {
      code: 'USER',
      description: 'User'
    }
  ];

  // File global configs
  public static SOCIETY_ID: string = 'society';
  public static FILE_TYPE_ID: string = 'fileType';
  public static FILE_INPUT_ID: string = 'fileInput';

  public static RELFAC_MAPPING: MifaOption = {
    code: 'RELFAC',
    description: 'Relfac file',
    pattern: 'Relfac.*|RELFAC.*',
    readablePattern: 'Relfac*|RELFAC*'
  };

  private static HEDGING_MAPPING: MifaOption = {
    code: 'HEDGING',
    description: 'Hedging file',
    pattern: '.*MIFA_Hedging.*.csv',
    readablePattern: 'MIFA_Hedging*.csv'
  };

  private static FACTORING_REPORTS_MAPPING: MifaOption = {
    code: '20',
    description: 'Factoring Reports',
    pattern: '.*[0-9]{3}_[0-9]{4}-[0-9]{2}-[0-9]{2}.*',
    readablePattern: 'XXX_YYYY-MM-DD_* (XXX = society code with 0 left pad)'
  };

  private static INTERCO_PAYMENT_TERMS_MAPPING: MifaOption = {
    code: '19',
    description: 'Interco Payment Terms',
    pattern: '.*[0-9]{3}_[0-9]{4}-[0-9]{2}-[0-9]{2}.*',
    readablePattern: 'XXX_YYYY-MM-DD_*.pdf|xls'
  };

  private static FACTORING_PAYMENTS_MAPPING: MifaOption = {
    code: '18',
    description: 'Factoring Payments',
    pattern: '.*[0-9]{3}_[0-9]{4}-[0-9]{2}-[0-9]{2}.*',
    readablePattern: 'XXX_YYYY-MM-DD_*.pdf|xls'
  };

  private static FACTORING_REPURCHASE_MAPPING: MifaOption = {
    code: '17',
    description: 'Factoring Repurchase AR / AP Letters',
    pattern: '.*[0-9]{3}_[0-9]{4}-[0-9]{2}-[0-9]{2}.*',
    readablePattern: 'XXX_YYYY-MM-DD_*.pdf|xls'
  };

  public static INPUT_FILE_TYPES: MifaOption[] = [
    this.RELFAC_MAPPING
    //, this.HEDGING_MAPPING
  ];

  public static PUBLICATION_FILE_TYPES: MifaOption[] = [
    this.FACTORING_REPURCHASE_MAPPING,
    this.FACTORING_PAYMENTS_MAPPING,
    this.INTERCO_PAYMENT_TERMS_MAPPING,
    this.FACTORING_REPORTS_MAPPING
  ];

  public static TXT_MAPPING: FileMapping = {
    mime: 'text/plain',
    extension: '.txt'
  };

  public static PDF_MAPPING: FileMapping = {
    mime: 'application/pdf',
    extension: '.pdf'
  };

  public static XLS_MAPPING: FileMapping = {
    mime: 'application/vnd.ms-excel',
    extension: '.xls'
  };

  // Relfac block config

  public static RELFAC_HEADER_BLOCK: RelfacBlockMapping = {
    code: '01',
    description: 'Relfac Header'
  };

  public static RELFAC_FOOTER_BLOCK: RelfacBlockMapping = {
    code: '02',
    description: 'Relfac Footer'
  };

  public static RELFAC_INVOICE_BLOCK: RelfacBlockMapping = {
    code: '03',
    description: 'Relfac Header'
  };

  public static RELFAC_FILE_FOOTER_BLOCK: RelfacBlockMapping = {
    code: '99',
    description: 'Relfac File Footer'
  };

  // File operations
  public static OPERATION_UPLOAD: string = 'upload';
  public static OPERATION_REPLACE: string = 'replace';

  /** Date Formats  */
  public static YYYY_MM_DD_T_HH_MM_SS: string = 'YYYY-MM-DDThh:mm:ss';
  public static YYYY_MM_DD_T_HH_MM: string = 'YYYY-MM-DDThh:mm';
  public static YYYY_MM_DD: string = 'YYYY-MM-DD';

  // Http content types
  public static CONTENT_TYPE_JSON: string = 'application/json; charset=utf-8';
  public static CONTENT_TYPE_OCTET_STREAM: string = 'application/octet-stream';
  public static RESPONSE_TYPE_JSON: string = 'json';
  public static RESPONSE_TYPE_BLOB: string = 'blob';
  public static RESPONSE_TYPE_PDF: string = 'pdf';

  // log levels
  public static LOG_LEVEL: MifaOption[] = [
    {
      code: 'TRACE',
      description: 'TRACE'
    }, {
      code: 'DEBUG',
      description: 'DEBUG'
    }, {
      code: 'INFO',
      description: 'INFO'
    }, {
      code: 'WARN',
      description: 'WARN'
    }, {
      code: 'ERROR',
      description: 'ERROR'
    }, {
      code: 'FATAL',
      description: 'FATAL'
    }
  ];

  // log Types
  public static LOG_TYPES: MifaOption[] = [
    {
      code: 'Technical',
      description: 'Technical'
    }, {
      code: 'Functional',
      description: 'Functional'
    }, {
      code: 'User',
      description: 'User'
    }
  ];

  /** QXT variable error validation **/
  // FX Request
  private static FX_REQUEST_COMMON_VARIABLES = [
    '[Entity]',
    '[Currency]',
    '[OtherCurrency]',
    '[DealDate]',
    '[MaturityDate]',
    '[CounterpartyReference]',
    '[Factoring AP / AR]',
    '[MIFA Invoicing Month]'
  ];
  public static FX_REQUEST_BUY_VARIABLES = GLOBAL_CONFIGS.FX_REQUEST_COMMON_VARIABLES.concat('[FaceValue]');
  public static FX_REQUEST_SELL_VARIABLES = GLOBAL_CONFIGS.FX_REQUEST_COMMON_VARIABLES.concat('[OtherFaceValue]');

  public static FINALIZE_FX_HEDGE_ACTION_NAME: string = 'Finalize FX Hedge';

}
